<template>
  <HeaderBase>
    <template #left>
      <AIconButton
        icon-name="hamburger"
        @click="openSidebar('burger')"
      />
    </template>

    <template #right>
      <AIconButton
        icon-name="location-grey-header"
        @click="openShopModal()"
      />
      <MPopover>
        <template #activator>
          <AIconButton icon-name="phone-outline"/>
        </template>

        <template #content="{ hide }">
          <MSupportPopover @close="hide" />
        </template>
      </MPopover>
    </template>
  </HeaderBase>
</template>

<script setup lang="ts">
import { useShopStore } from '@/stores/shop'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'

import MPopover from '@/components/molecules/Popover/MPopover.vue'
import MSupportPopover from '@/components/molecules/SupportPopover/MSupportPopover.vue'

import HeaderBase from './HeaderBase.vue'

const { openSidebar } = useModals()
const { openShopModal } = useShopStore()
</script>
