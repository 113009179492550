<template>
  <div class="header-bar">
    <div class="container">
      <div class="header-bar__wrapper">
        <div class="header-bar__item">
          <ALink
            v-if="locale === 'ru'"
            color="grey"
            size="sm"
            class="i-city-grey"
            :class="{ active: hasCities }"
            @click.stop="openCityModal"
          >
            {{ cityName }}
          </ALink>

          <HeaderRedirectPopover/>
        </div>

        <div class="header-bar__row">
          <MPopover position="bottom-end">
            <template #activator>
              <div class="header-bar__item">
                <ALink
                  color="grey"
                  size="sm"
                >
                  {{ $t("molecules.header.business") }}
                </ALink>
              </div>
            </template>
            <template #content="{ hide }">
              <MListItem
                v-for="(item, index) in businessLinks"
                :key="index"
                @click.stop="routerPage(item, () => hide())"
              >
                {{ item.title }}
              </MListItem>
            </template>
          </MPopover>

          <div v-if="hasShops" class="header-bar__item">
            <ALink
              color="grey"
              size="sm"
              @click="openShopModal"
            >
              {{ $t("molecules.header.shop") }}
            </ALink>
          </div>

          <div class="header-bar__item">
            <MPopover position="bottom-end" class-name="header-bar__popover-support">
              <template #activator>
                <ALink
                  color="grey"
                  size="sm"
                  prevent
                >
                  {{ $t("molecules.header.help") }}
                </ALink>
              </template>
              <template #content="{ hide }">
                <MSupportPopover @close="hide()" />
              </template>
            </MPopover>
          </div>

          <LanguageSwitch
            v-if="isLangSwitching"
            position="bottom-end"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import { useLocalization, useHydrationRef } from '#imports'
import { useNavigate } from '@/composables/useNavigate'
import { useConfig } from '@/stores/config'
import { useShopStore } from '@/stores/shop'
import { useMainStore } from '@/stores'

import type { RouteLocationPathRaw } from 'vue-router'

import HeaderRedirectPopover from './HeaderRedirectPopover.vue'
import ALink from '@/components/atoms/Link/ALink.vue'
import LanguageSwitch from '@/components/ui-kit/m-language/LanguageSwitch.vue'
import MListItem from '@/components/molecules/ListItem/MListItem.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'
import MSupportPopover from '@/components/molecules/SupportPopover/MSupportPopover.vue'

defineComponent({ name: 'HeaderBar' })

const showCityAlert = useHydrationRef('city-alert', false)

const { locale, t } = useLocalization()
const { getRegionsByCountryCode, mainRegionId, currentRegion, isLangSwitching, currentRegionName, isMscRegion } = useConfig()
const { getShops, openShopModal } = useShopStore()
const { openModalCity } = useMainStore()

const { navigatePush } = useNavigate()

const cityName = computed(() => {
  const allRegions = useMainDomainCookie('all-regions')
  let name = currentRegionName.value

  if (allRegions.value && currentRegion.value.id === mainRegionId.value.ru) {
    name += ' ' + t('other.and-regions')
  }

  return name
})

const [{ data: hasShops }, { data: hasCities }] = await Promise.all([
  useHydrationData('shops-has-any', async () => {
    const data = await getShops()

    return data.length > 0
  }),
  useHydrationData('cities-has-any', async () => {
    try {
      const cities = await getRegionsByCountryCode(currentRegion.value.country?.code ?? '')

      return cities.length > 1
    } catch (e) {
      return false
    }
  })
])

const businessLinks = [
  {
    title: t('organisms.footer.corp-clients'),
    link: '/pages/corporate.html'
  },
  {
    title: t('organisms.footer.franchise'),
    link: '/pages/franchise.html'
  },
  {
    title: t('organisms.footer.rest-shops'),
    link: '/opt/'
  },
  ...(isMscRegion
    ? [
        {
          title: t('organisms.footer.lessors'),
          link: '/pages/for_lessors.html'
        },
        {
          title: t('organisms.footer.suppliers'),
          link: '/pages/suppliers.html'
        }
      ]
    : [
        {
          title: t('organisms.footer.suppliers'),
          link: '/pages/suppliers.html'
        }
      ]
  )
  // ...(isMscRegion
  //   ? [{
  //       title: t('organisms.footer.region'),
  //       link: '/pages/reg_partnership.html'
  //     }]
  //   : [])
  // { title: t("pages.corporate.opt"), link: "pages/corporate.html" },
]

const routerPage = (item: { link: string | RouteLocationPathRaw }, callback?: () => void): void => {
  navigatePush(item.link, false)

  if (callback) {
    callback()
  }
}

const closeCityPopover = (): void => {
  showCityAlert.value = false
}

const openCityModal = (): void => {
  if (hasCities.value) {
    closeCityPopover()
    openModalCity()
  }
}
</script>

<style lang="postcss">

.header-bar {
  position: relative;
  z-index: 102;
  display: none;
  padding-top: var(--spacer-2xs);
  background: var(--color-white);

  &__popover-support {
    min-width: 264px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__row {
    display: flex;
    gap: var(--spacer-sm);
  }

  &__geo-popover {
    width: 14rem;
    padding: 0 var(--spacer-3xs);
    color: var(--color-neutral-900);

    .logo {
      display: flex;
      max-width: 7rem;
      margin: var(--spacer-5xs) auto 0;
    }

    .title {
      @mixin text-lg;
    }

    .description {
      color: var(--color-neutral-600);
      text-align: center;

      @mixin text-xs;
    }

    .buttons {
      display: grid;
      width: 100%;
      margin: var(--spacer-xs) 0 var(--spacer-3xs);

      .button:not(:first-child) {
        margin-top:  var(--spacer-3xs);
      }

      .action-btn {
        margin: var(--spacer-2xs) auto auto;

        @media (--screen-xs) {
          margin-top: var(--spacer-base);
        }
      }

      @media (--screen-xs) {
        .button {
          height: var(--spacer-4xl);
        }
      }
    }

    @media (--screen-xs) {
      width: 100%;

      .content {
        padding-right: var(--spacer-xs);
        padding-left: var(--spacer-xs);
      }
    }
  }

  & .link {
    margin-right: 18px;
  }

  &__item {
    position: relative;

    & .link {
      display: flex;
      gap: var(--spacer-4xs);
      align-items: center;
      align-self: center;
      margin-right: 0;
      cursor: pointer;

      &.active.link--text {
        cursor: pointer;
      }

      &[class*="i-"]::before {
        width: 1rem;
        height: 1rem;
      }

      @media (hover: hover) and (--screen-lg) {
        &.active:hover.i-city-grey::before {
          background-image: url("/assets/icons/general.svg#city--hover");
        }

        &:hover.i-location-grey::before {
          background-image: url("/assets/icons/general.svg#location--hover");
        }

        &:hover.i-message-grey::before {
          background-image: url("/assets/icons/general.svg#message--hover");
        }
      }
    }

    & .popover + .link {
      color: var(--color-blue-800);

      &.i-message-grey::before {
        background-image: url("/assets/icons/general.svg#message--hover");
      }
    }

    @media (hover: hover) and (--screen-lg) {
      &.left .link:not(.active).link--text:hover {
        --link-color: var(--color-text-middle);
      }
    }
  }

  @media (--screen-lg) {
    display: block;
  }
}
</style>
