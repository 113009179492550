<template>
  <MPopover
    is-hover
    position="bottom-end"
    class-name="m-header-center__popover"
    :open="popoverCartShow"
    :disable="true"
    @close="popoverCartShow = false"
  >
    <template #activator>
      <NavItem
        :to="getUrl('cart.php', false)"
        :label="$t('molecules.header.cart')"
        :badge="cartProductsQuantity"
        icon="i-cart-header"
        no-prefetch
      />
    </template>
    <template #content>
      <div class="m-header-center__bar-offer">
        <div class="content">
          <Suspense>
            <LazyMProductHeaderCart
              v-for="(item, index) in cartProducts"
              :key="index"
              :item="item.product"
              @remove:cart="removeCart(item.id)"
            />
          </Suspense>
        </div>
<!--        <Suspense>-->
<!--          <LazyOBottomBar btn animate>-->
<!--            <div v-if="total?.amount">-->
<!--              <LazyAPrice-->
<!--                :regular-price="total?.amount"-->
<!--                :currency="total?.currencyCodeIso"-->
<!--                size="sm"-->
<!--              />-->
<!--            </div>-->
<!--            <RouterLink to="/cart.php">-->
<!--              <AButton variant="success">-->
<!--                {{ $t("organisms.tabbar.cart") }}-->
<!--              </AButton>-->
<!--            </RouterLink>-->
<!--          </LazyOBottomBar>-->
<!--        </Suspense>-->
      </div>
    </template>
  </MPopover>
</template>

<script setup lang="ts">
import { defineAsyncComponent, hydrateOnVisible } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import { useCartStore } from '@/stores/cart'

import NavItem from './HeaderNavItem.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'

const LazyMProductHeaderCart = defineAsyncComponent({
  loader: () => /* @vite-ignore */ import('@/components/molecules/ProductHeaderCart/MProductHeaderCart.vue'),
  hydrate: hydrateOnVisible()
})

const { getUrl } = useNavigate()
const {
  cartProductsQuantity,
  cartProducts,
  popoverCartShow,
  removeProductFromCart
} = useCartStore()

function removeCart (id: number) {
  if (id) {
    removeProductFromCart([id])
  }
}
</script>
