import { useCacheData } from '~/modules/nuxt-cache/composables/cache-data'
import { useAPI } from '~/modules/nuxt-api/composables'
import { isValueEmpty } from '~/utils/check-empty-value'

export function useNotification () {
  const api = useAPI()

  async function getNotifications () {
    try {
      const { value, addToCache } = await useCacheData<string[]>('region-announcements')

      if (value) {
        return value
      }

      const { getRegionAnnouncements } = api.region()
      const regionAnnouncements = await getRegionAnnouncements()
      const announcements = regionAnnouncements?.map(item => item.text) ?? []

      if (!isValueEmpty(announcements)) {
        const datesTo = regionAnnouncements?.map((item) => {
          return item.publicationInterval ? new Date(item.publicationInterval.dateToIso).getTime() : 0
        }) ?? []
        const min_exp = Math.min(...datesTo)

        await addToCache(announcements, (min_exp - Date.now()) / 1000)
      }

      return announcements
    } catch (err) {
      return []
    }
  }

  return {
    getNotifications
  }
}
