<template>
  <NotificationBanner
    v-if="isNotificationShow"
    :notification="notifications[0]"
  />
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'
import { useHydrationData, useContextData } from '#imports'

import { useNotification } from '@/composables/useNotification'

import NotificationBanner from '@/components/molecules/Notification/NotificationBanner.vue'

defineComponent({ name: 'HomeNotificationBanner' })

const { getNotifications } = useNotification()

const isNotificationShow = useContextData('home-notification-show')

const { data: notifications } = await useHydrationData('home-notification', getNotifications, {
  then ({ data }) {
    isNotificationShow.value = data.value?.length > 0
  }
})
</script>
