<template>
  <HeaderBase>
    <template #left>
      <AIconButton
        icon-name="arrow-line"
        @click="goBack"
      />
    </template>

    <template #middle>
      <a class="header-profile__logo" href="/">
        <AImage
          from-public
          src="/assets/images/logo.svg"
          width="120"
          height="24"
        />
      </a>
    </template>

    <template #right>
      <MPopover>
        <template #activator>
          <AIconButton icon-name="comment-gray"/>
        </template>

        <template #content="{ hide }">
          <MSupportPopover @close="hide" />
        </template>
      </MPopover>
    </template>
  </HeaderBase>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useNavigate } from '@/composables/useNavigate'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

import MPopover from '@/components/molecules/Popover/MPopover.vue'
import MSupportPopover from '@/components/molecules/SupportPopover/MSupportPopover.vue'

import HeaderBase from './HeaderBase.vue'

const { navigatePush } = useNavigate()
const router = useRouter()
const route = useRoute()

const goBack = () => {
  const name = route?.name?.toString?.()
  const hasBackHistory = !!router.options.history.state?.back

  if (hasBackHistory) {
    router.back()
  } else if (name?.includes?.('private_office-')) {
    navigatePush('private_office')
  } else {
    navigatePush('')
  }
}
</script>

<style>
.header-profile__logo {
  display: none;
  margin: 0 auto;

  & > .image {
    display: flex;
    width: 7.5rem;
    height: 1.5rem;
  }

  @media (--screen-xs) {
    display: flex;

    & + .header__input {
      display: none;
    }
  }
}
</style>
