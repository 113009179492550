<template>
  <div class="tabbar">
    <NuxtLink
      v-for="item in items"
      :key="`tabbar-item-${item.key}`"
      :to="item.href !== undefined ? getUrl(item.href, item.addSlash) : undefined"
      no-prefetch
      :class="{ active: isActive(item) }"
      @click.stop="goTo(item)"
    >
      <div :class="`i-${item.iconName}`">
        <ServerRender
          is="Badge"
          v-if="counts[item.key]"
          size="xs"
          border="xs"
        >
          {{ counts[item.key] }}
        </ServerRender>
      </div>
      <div>
        {{ $t(`organisms.tabbar.${item.key}`) }}
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import ServerRender from '@/components/helpers/ServerRender'

import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useNavigate } from '@/composables/useNavigate'
import { useCartStore } from '@/stores/cart'
import { useFavoritesStore } from '@/stores/favorites'
import { getTabBarData } from '@/components/organisms/TabBar/data'

import type { TabBarItem } from '@/components/organisms/TabBar/data'

defineComponent({ name: 'OTabBar' })

const { getUrl } = useNavigate()
const route = useRoute()
const { openModal, closeModal, activeModal } = useModals()

const { cartProductsQuantity } = useCartStore()
const { productIds } = useFavoritesStore()

const items = getTabBarData()
const activeTab = ref('')

const isActive = (item: TabBarItem): boolean => {
  return activeModal.value?.[0] === 'search' ? item.key === 'catalog' : activeTab.value === item.key
}

const goTo = (item: TabBarItem) => {
  if (item.key === 'catalog') {
    activeModal.value?.[0] === 'search' ? closeModal() : openModal('search')
  } else {
    activeTab.value = item.key
  }
}

const counts = computed<Record<string, number>>(() => {
  if (process.server) {
    return {} as Record<string, number>
  }

  return {
    cart: cartProductsQuantity.value,
    favorites: productIds.value?.length ?? 0,
    profile: 0
  }
})

const checkPath = () => {
  activeTab.value = route.path === '/cart.php'
    ? 'cart'
    : route.path.includes('wishlist')
      ? 'favorites'
      : items.find(item => getUrl(item.href ?? '') === route.path)?.key ?? ''
}

if (process.client) {
  watch(() => route.path, () => checkPath())
}

onMounted(() => checkPath())
</script>

<style lang="postcss">
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 130;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  padding: var(--spacer-4xs);
  padding-bottom: calc(var(--spacer-4xs) + env(safe-area-inset-bottom));
  border-top: 1px solid var(--color-neutral-300);
  background-color: var(--color-white);
  user-select: none;

  & > a {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 var(--spacer-4xs);
    color: inherit;
    text-decoration: none;

    &.active,
    &:active {
      color: var(--color-neutral-500);

      & > div:last-child {
        color: var(--color-red-400);
      }

      & > [class*="i-"]::before {
        transform: translateX(0);
      }

      & > .i-house-grey::before {
        background-image: url("/assets/icons/general.svg#house-primary");
      }

      & > .i-catalog-grey::before {
        background-image: url("/assets/icons/general.svg#catalog-primary");
      }

      & > .i-cart-grey::before {
        background-image: url("/assets/icons/general.svg#cart-primary");
      }

      & > .i-favorite-tabbar::before {
        background-image: url("/assets/icons/general.svg#favorite-tabbar-primary");
      }

      & > .i-user-grey::before {
        background-image: url("/assets/icons/general.svg#user-primary");
      }
    }

    & > div:last-child {
      width: 100%;
      margin-top: var(--spacer-4xs);
      color: var(--color-neutral-600);
      text-align: center;

      @mixin text-xs;
    }

    & > div:first-child {
      position: relative;
      height: 24px;
      margin-top: var(--spacer-4xs);
      color: var(--color-neutral-500);

      & > .badge {
        position: absolute;
        top: 0;
        right: auto;
        left: 19px;
        width: auto;
      }
    }
  }

  @media (--screen-lg) {
    display: none;
  }
}

@media (--screen-xs) {
  body {
    padding-bottom: calc(3.5rem + env(safe-area-inset-bottom));
  }
}
</style>
